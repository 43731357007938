<template>
  <b-container class="b-card-forms">
    <b-row class="vh-100" align-h="center">
      <b-col align-self="center" cols="12" sm="9" lg="5">
        <b-card class="text-center error">

          <h1 class="mb-3">{{ $t('freeAccountExpired.title') }}</h1>
          <p class="mb-2 text-dark" v-html="$t('freeAccountExpired.desc')"></p>
          <router-link class="d-block mt-4" to="/webmag-logout">
            {{ $t('buttons.logout') }}
          </router-link>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Auth from '@aws-amplify/auth';
import SetLanguageToBrowserLanguage from '@/mixins/setLanguageToBrowserLanguage';

export default {
  name: 'FreeAccountExpired',
  mixins: [SetLanguageToBrowserLanguage],
  data() {
    return {
      problem: null,
    };
  },
  async created() {
    try {
      const userInfo = await Auth.currentUserInfo();
      if (!userInfo) {
        this.setLanguageToBrowserLanguage();
      }
    } catch (error) {
      this.setLanguageToBrowserLanguage();
    }
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 13px;
  font-weight: bold;
}
.error-msg-block {
  font-size: 12px;
  line-height: 15px;
  color: #000;
}
.group-settings #content .container{
  margin-left:auto !important
}
</style>
